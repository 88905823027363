@import url(~quill/dist/quill.core.css);
@import url(~quill/dist/quill.snow.css);
@import url(~quill/dist/quill.bubble.css);
@import url(../node_modules/angular-calendar/css/angular-calendar.css);
.light-style .sidenav .app-brand.demo {
  height: 66px;
}

.dark-style .sidenav .app-brand.demo {
  height: 66px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.app-brand-logo.demo svg {
  width: 32px;
  height: 32px;
}

.app-brand-text.demo {
  font-size: 1.1rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (min-width: 992px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}

/* *****************************************************************************
 * Navbar
 */
.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (min-width: 992px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: .5rem;
    width: 22rem;
  }
  .demo-navbar-user .dropdown-menu {
    margin-top: .25rem;
  }
}

/* *****************************************************************************
 * Content
 */
.demo-vertical-spacing > * + * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-sm > * + * {
  margin-top: .9375rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg > * + * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-inline-spacing > *,
.demo-paragraph-spacing > p > * {
  margin: 0 .375rem .9375rem 0 !important;
}

.demo-paragraph-spacing > p {
  margin: 0;
}

.demo-blocks > * {
  display: block !important;
}

html:not(.material-style) .material-style-only {
  display: none !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir=rtl] .rtl-only {
  display: block !important;
}

/* *****************************************************************************
 * Layouts
 */
.layout-example-block {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.04);
  color: #c7254e;
}

.dark-style .layout-example-block {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
}

.layout-example-block code {
  background: none;
  color: #666;
}

.dark-style .layout-example-block code {
  color: #fff;
}

.layout-example-block-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 500px;
}

.layout-example-block-sidenav {
  width: 24%;
  background: #607d8b !important;
}

.layout-example-block-sidenav code {
  color: #fff;
}

.layout-example-block-navbar {
  background: #3ca99e !important;
}

.layout-example-block-navbar code {
  color: #fff;
}

.layout-example-block-content {
  background: #9e9e9e !important;
}

.layout-example-block-content code {
  color: #fff;
}

.layout-example-block-1 .layout-example-block-content {
  width: 74%;
}

.layout-example-block-2 .layout-example-block-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 74%;
}

.layout-example-block-2 > .layout-example-block {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.layout-example-block-2 .layout-example-block-content {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout-example-block-1-no-sidenav .layout-example-block-content {
  width: 100%;
}

.layout-example-code {
  display: block;
  overflow: auto;
  margin: 0;
  padding: 30px;
  min-width: 0;
  width: 100%;
  background: #fff;
}

.dark-style .layout-example-code {
  background: #2f3238;
}

.layout-example-run {
  display: inline-block;
  margin: 3px;
  padding: 4px 6px;
  border: 1px solid #eee;
  background: transparent;
  white-space: nowrap;
  line-height: 1;
}

.dark-style .layout-example-run {
  border-color: rgba(255, 255, 255, 0.2);
}

.layout-example-block-3 .layout-example-block-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout-example-block-3 .layout-example-block-inner > .layout-example-block {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.layout-example-block-3 .layout-example-block-inner .layout-example-block-container-fluid {
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.layout-example-block-3 .layout-example-block-sidenav-horizontal {
  background: #607d8b !important;
}

.layout-example-block-3 .layout-example-block-container-fluid {
  background: #fff !important;
}

.layout-example-block-3 .layout-example-block-container-fluid code {
  color: #666 !important;
}

.layout-example-block-3 .layout-example-block-footer {
  background: #e6514a !important;
}

.dark-style .layout-example-block-content .layout-example-block:not(.layout-example-block-sidenav-horizontal) code {
  color: #666 !important;
}

.toast-title {
  font-weight: bold;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999999;
  -webkit-box-shadow: 0 0 12px #999999;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000000;
  -webkit-box-shadow: 0 0 12px #000000;
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

#toast-container > div {
  opacity: 1;
  width: 20em !important;
  padding: 15px 15px 15px 50px !important;
}

[dir=rtl] #toast-container > div {
  background-position: center right .9375rem;
  padding: 15px 50px 15px 15px !important;
}

.toast-progress {
  position: absolute;
  top: 0;
  bottom: auto;
  height: .1875rem;
  opacity: .15;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=15);
  filter: alpha(opacity=15);
}

[dir=rtl] .toast-progress {
  right: 0;
  left: auto;
}

.toast-close-button {
  position: absolute;
  top: 0;
  color: inherit !important;
  text-shadow: none;
  right: .25rem !important;
}

[dir=rtl] .toast-close-button {
  right: auto !important;
  left: .25rem !important;
}

#toast-container > .toast {
  background-size: 28px;
  background-repeat: no-repeat;
}

#toast-container > .toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23FFFFFF' d='M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z'/%3E%3Cpath fill='%23FFFFFF' d='M277 360h-42V235h42v125zm0-166h-42v-42h42v42z'/%3E%3C/svg%3E") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23FFFFFF' d='M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z'/%3E%3C/svg%3E") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23FFFFFF' d='M186.301 339.893L96 249.461l-32 30.507L186.301 402 448 140.506 416 110z'/%3E%3C/svg%3E") !important;
}

[dir=rtl] #toast-container > .toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23FFFFFF' d='M96 110l-32 30.5L325.7 402L448 280l-32-30.5l-90.3 90.4L96 110z'/%3E%3C/svg%3E") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='%23665720' d='M32 464h448L256 48 32 464zm248-64h-48v-48h48v48zm0-80h-48v-96h48v96z'/%3E%3C/svg%3E") !important;
  background-size: 25px;
}

.light-style #toast-container {
  z-index: 999999;
}

.light-style #toast-container > div {
  border-radius: 0.25rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09) !important;
}

.light-style #toast-container .toast-close-button {
  font-weight: 300;
}

.light-style #toast-container > .toast-success {
  background-color: #02BC77;
  color: #fff;
}

.light-style #toast-container > .toast-error {
  background-color: #d9534f;
  color: #fff;
}

.light-style #toast-container > .toast-info {
  background-color: #28c3d7;
  color: #fff;
}

.light-style #toast-container > .toast-warning {
  background-color: #FFD950;
  color: #665720;
}

.material-style #toast-container {
  z-index: 999999;
}

.material-style #toast-container > div {
  border-radius: 0.125rem;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08) !important;
}

.material-style #toast-container .toast-close-button {
  font-weight: 300;
}

.material-style #toast-container > .toast-success {
  background-color: #02BC77;
  color: #fff;
}

.material-style #toast-container > .toast-error {
  background-color: #d9534f;
  color: #fff;
}

.material-style #toast-container > .toast-info {
  background-color: #28c3d7;
  color: #fff;
}

.material-style #toast-container > .toast-warning {
  background-color: #FFD950;
  color: #665720;
}

.dark-style #toast-container {
  z-index: 999999;
}

.dark-style #toast-container > div {
  border-radius: 0.25rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2) !important;
}

.dark-style #toast-container .toast-close-button {
  font-weight: 300;
}

.dark-style #toast-container > .toast-success {
  background-color: #02BC77;
  color: #fff;
}

.dark-style #toast-container > .toast-error {
  background-color: #d9534f;
  color: #fff;
}

.dark-style #toast-container > .toast-info {
  background-color: #28c3d7;
  color: #fff;
}

.dark-style #toast-container > .toast-warning {
  background-color: #FFD950;
  color: #665720;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

tag-input tag-ripple {
  display: none !important;
}

tag-input .ng2-tag-input[class] {
  display: block;
  width: 100%;
}

tag-input .ng2-tag-input[class] .ng2-tags-container {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

tag-input .ng2-tag-input[class] tag,
tag-input .ng2-tag-input[class] .ng2-tag-input__text-input {
  min-height: 1.5rem;
  height: 1.5rem;
  letter-spacing: normal;
  line-height: 1.5rem;
}

tag-input .ng2-tag-input[class] .tag-wrapper {
  display: flex;
  flex: 0 1 auto;
}

tag-input .ng2-tag-input[class] tag {
  display: block;
  flex: 0 1 auto;
}

tag-input .ng2-tag-input[class] tag:not(.tag--editing) {
  box-shadow: none !important;
}

tag-input .ng2-tag-input[class] tag.tag--editing {
  border: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) inset;
}

tag-input .ng2-tag-input[class] tag delete-icon {
  opacity: .5;
}

[dir=rtl] tag-input .ng2-tag-input[class] tag delete-icon {
  text-align: left;
}

tag-input .ng2-tag-input[class] tag delete-icon:hover, tag-input .ng2-tag-input[class] tag delete-icon:focus {
  opacity: 1;
  transform: none;
}

tag-input .ng2-tag-input[class] tag delete-icon svg {
  height: 1.5rem !important;
}

tag-input .ng2-tag-input[class].ng2-tag-input--disabled tag {
  cursor: default !important;
}

tag-input .ng2-tag-input[class].ng2-tag-input--disabled .ng2-tag-input__text-input {
  display: none !important;
}

tag-input .ng2-tag-input__text-input {
  background: none;
}

tag-input .ng2-tag-input__text-input::placeholder {
  opacity: 1;
}

.light-style tag-input .ng2-tag-input {
  padding: 0 0 0.375rem 0;
  min-height: calc(1.54em + 0.876rem + 2px);
  border-color: rgba(24, 28, 33, 0.1);
}

.light-style tag-input .ng2-tag-input tag,
.light-style tag-input .ng2-tag-input .ng2-tag-input__text-input {
  margin: 0.375rem 0.375rem 0 0;
  padding: 0 0.417em;
  color: #4E5155;
  font-size: 0.894rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.light-style tag-input .ng2-tag-input tag {
  background-color: rgba(24, 28, 33, 0.1);
  border-radius: 0.125rem;
}

.light-style tag-input .ng2-tag-input tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background-color: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.light-style tag-input .ng2-tag-input tag:not(.readonly):not(.tag--editing):focus, .light-style tag-input .ng2-tag-input tag:not(.readonly):not(.tag--editing):active {
  background-color: rgba(24, 28, 33, 0.2);
  color: #4E5155;
}

.light-style tag-input .ng2-tag-input tag path {
  fill: #4E5155 !important;
}

.light-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(.readonly):not(.tag--editing):focus,
.light-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(.readonly):not(.tag--editing):active,
.light-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background-color: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.light-style tag-input .ng2-tag-input__text-input::placeholder {
  color: #babbbc;
}

.light-style .is-valid tag-input .ng2-tag-input:not(.ng2-tag-input--focused),
.light-style tag-input.is-valid .ng2-tag-input:not(.ng2-tag-input--focused) {
  border-color: #02BC77 !important;
}

.light-style .is-invalid tag-input .ng2-tag-input:not(.ng2-tag-input--focused),
.light-style tag-input.is-invalid .ng2-tag-input:not(.ng2-tag-input--focused) {
  border-color: #d9534f !important;
}

.light-style .ngx-chips-secondary .ng2-tag-input tag {
  background-color: #8897AA !important;
  color: #fff !important;
}

.light-style .ngx-chips-secondary .ng2-tag-input tag:focus, .light-style .ngx-chips-secondary .ng2-tag-input tag:active {
  background-color: #66788f !important;
  color: #fff !important;
}

.light-style .ngx-chips-secondary .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.light-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #8897AA !important;
  color: #fff !important;
}

.light-style .ngx-chips-success .ng2-tag-input tag {
  background-color: #02BC77 !important;
  color: #fff !important;
}

.light-style .ngx-chips-success .ng2-tag-input tag:focus, .light-style .ngx-chips-success .ng2-tag-input tag:active {
  background-color: #017f51 !important;
  color: #fff !important;
}

.light-style .ngx-chips-success .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.light-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #02BC77 !important;
  color: #fff !important;
}

.light-style .ngx-chips-info .ng2-tag-input tag {
  background-color: #28c3d7 !important;
  color: #fff !important;
}

.light-style .ngx-chips-info .ng2-tag-input tag:focus, .light-style .ngx-chips-info .ng2-tag-input tag:active {
  background-color: #1e94a3 !important;
  color: #fff !important;
}

.light-style .ngx-chips-info .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.light-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #28c3d7 !important;
  color: #fff !important;
}

.light-style .ngx-chips-warning .ng2-tag-input tag {
  background-color: #FFD950 !important;
  color: #665720 !important;
}

.light-style .ngx-chips-warning .ng2-tag-input tag:focus, .light-style .ngx-chips-warning .ng2-tag-input tag:active {
  background-color: #ffcc13 !important;
  color: #665720 !important;
}

.light-style .ngx-chips-warning .ng2-tag-input tag delete-icon svg path {
  fill: #665720 !important;
}

.light-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #FFD950 !important;
  color: #665720 !important;
}

.light-style .ngx-chips-danger .ng2-tag-input tag {
  background-color: #d9534f !important;
  color: #fff !important;
}

.light-style .ngx-chips-danger .ng2-tag-input tag:focus, .light-style .ngx-chips-danger .ng2-tag-input tag:active {
  background-color: #c12e2a !important;
  color: #fff !important;
}

.light-style .ngx-chips-danger .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.light-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #d9534f !important;
  color: #fff !important;
}

.light-style .ngx-chips-light .ng2-tag-input tag {
  background-color: rgba(24, 28, 33, 0.06) !important;
  color: #0a0b0d !important;
}

.light-style .ngx-chips-light .ng2-tag-input tag:focus, .light-style .ngx-chips-light .ng2-tag-input tag:active {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #0a0b0d !important;
}

.light-style .ngx-chips-light .ng2-tag-input tag delete-icon svg path {
  fill: #0a0b0d !important;
}

.light-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: rgba(24, 28, 33, 0.06) !important;
  color: #0a0b0d !important;
}

.light-style .ngx-chips-dark .ng2-tag-input tag {
  background-color: rgba(24, 28, 33, 0.9) !important;
  color: #fff !important;
}

.light-style .ngx-chips-dark .ng2-tag-input tag:focus, .light-style .ngx-chips-dark .ng2-tag-input tag:active {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #fff !important;
}

.light-style .ngx-chips-dark .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.light-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag,
.light-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.light-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: rgba(24, 28, 33, 0.9) !important;
  color: #fff !important;
}

.light-style[dir=rtl] tag-input .ng2-tag-input tag,
.light-style[dir=rtl] tag-input .ng2-tag-input .ng2-tag-input__text-input {
  margin: 0.375rem 0 0 0.375rem;
}

.material-style tag-input .ng2-tag-input {
  padding: 0 0 0.3125rem 0;
  min-height: 2.1875rem;
  border-color: rgba(24, 28, 33, 0.1);
}

.material-style tag-input .ng2-tag-input tag,
.material-style tag-input .ng2-tag-input .ng2-tag-input__text-input {
  margin: 0.3125rem 0.3125rem 0 0;
  padding: 0 0.417em;
  color: #4E5155;
  font-size: 0.894rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.material-style tag-input .ng2-tag-input tag {
  background-color: rgba(24, 28, 33, 0.1);
  border-radius: 0.125rem;
}

.material-style tag-input .ng2-tag-input tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background-color: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.material-style tag-input .ng2-tag-input tag:not(.readonly):not(.tag--editing):focus, .material-style tag-input .ng2-tag-input tag:not(.readonly):not(.tag--editing):active {
  background-color: rgba(24, 28, 33, 0.2);
  color: #4E5155;
}

.material-style tag-input .ng2-tag-input tag path {
  fill: #4E5155 !important;
}

.material-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(.readonly):not(.tag--editing):focus,
.material-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(.readonly):not(.tag--editing):active,
.material-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background-color: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.material-style tag-input .ng2-tag-input__text-input::placeholder {
  color: #babbbc;
}

.material-style .is-valid tag-input .ng2-tag-input:not(.ng2-tag-input--focused),
.material-style tag-input.is-valid .ng2-tag-input:not(.ng2-tag-input--focused) {
  border-color: #02BC77 !important;
}

.material-style .is-invalid tag-input .ng2-tag-input:not(.ng2-tag-input--focused),
.material-style tag-input.is-invalid .ng2-tag-input:not(.ng2-tag-input--focused) {
  border-color: #d9534f !important;
}

.material-style .ngx-chips-secondary .ng2-tag-input tag {
  background-color: #8897AA !important;
  color: #fff !important;
}

.material-style .ngx-chips-secondary .ng2-tag-input tag:focus, .material-style .ngx-chips-secondary .ng2-tag-input tag:active {
  background-color: #66788f !important;
  color: #fff !important;
}

.material-style .ngx-chips-secondary .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.material-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #8897AA !important;
  color: #fff !important;
}

.material-style .ngx-chips-success .ng2-tag-input tag {
  background-color: #02BC77 !important;
  color: #fff !important;
}

.material-style .ngx-chips-success .ng2-tag-input tag:focus, .material-style .ngx-chips-success .ng2-tag-input tag:active {
  background-color: #017f51 !important;
  color: #fff !important;
}

.material-style .ngx-chips-success .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.material-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #02BC77 !important;
  color: #fff !important;
}

.material-style .ngx-chips-info .ng2-tag-input tag {
  background-color: #28c3d7 !important;
  color: #fff !important;
}

.material-style .ngx-chips-info .ng2-tag-input tag:focus, .material-style .ngx-chips-info .ng2-tag-input tag:active {
  background-color: #1e94a3 !important;
  color: #fff !important;
}

.material-style .ngx-chips-info .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.material-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #28c3d7 !important;
  color: #fff !important;
}

.material-style .ngx-chips-warning .ng2-tag-input tag {
  background-color: #FFD950 !important;
  color: #665720 !important;
}

.material-style .ngx-chips-warning .ng2-tag-input tag:focus, .material-style .ngx-chips-warning .ng2-tag-input tag:active {
  background-color: #ffcc13 !important;
  color: #665720 !important;
}

.material-style .ngx-chips-warning .ng2-tag-input tag delete-icon svg path {
  fill: #665720 !important;
}

.material-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #FFD950 !important;
  color: #665720 !important;
}

.material-style .ngx-chips-danger .ng2-tag-input tag {
  background-color: #d9534f !important;
  color: #fff !important;
}

.material-style .ngx-chips-danger .ng2-tag-input tag:focus, .material-style .ngx-chips-danger .ng2-tag-input tag:active {
  background-color: #c12e2a !important;
  color: #fff !important;
}

.material-style .ngx-chips-danger .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.material-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #d9534f !important;
  color: #fff !important;
}

.material-style .ngx-chips-light .ng2-tag-input tag {
  background-color: rgba(24, 28, 33, 0.06) !important;
  color: #0a0b0d !important;
}

.material-style .ngx-chips-light .ng2-tag-input tag:focus, .material-style .ngx-chips-light .ng2-tag-input tag:active {
  background-color: rgba(0, 0, 0, 0.06) !important;
  color: #0a0b0d !important;
}

.material-style .ngx-chips-light .ng2-tag-input tag delete-icon svg path {
  fill: #0a0b0d !important;
}

.material-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: rgba(24, 28, 33, 0.06) !important;
  color: #0a0b0d !important;
}

.material-style .ngx-chips-dark .ng2-tag-input tag {
  background-color: rgba(24, 28, 33, 0.9) !important;
  color: #fff !important;
}

.material-style .ngx-chips-dark .ng2-tag-input tag:focus, .material-style .ngx-chips-dark .ng2-tag-input tag:active {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #fff !important;
}

.material-style .ngx-chips-dark .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.material-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag,
.material-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.material-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: rgba(24, 28, 33, 0.9) !important;
  color: #fff !important;
}

.material-style[dir=rtl] tag-input .ng2-tag-input tag,
.material-style[dir=rtl] tag-input .ng2-tag-input .ng2-tag-input__text-input {
  margin: 0.3125rem 0 0 0.3125rem;
}

.dark-style tag-input .ng2-tag-input {
  padding: 0 0 0.375rem 0;
  min-height: calc(1.54em + 0.876rem + 2px);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark-style tag-input .ng2-tag-input tag,
.dark-style tag-input .ng2-tag-input .ng2-tag-input__text-input {
  margin: 0.375rem 0.375rem 0 0;
  padding: 0 0.417em;
  color: #fff;
  font-size: 0.894rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.dark-style tag-input .ng2-tag-input tag {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.125rem;
}

.dark-style tag-input .ng2-tag-input tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.dark-style tag-input .ng2-tag-input tag:not(.readonly):not(.tag--editing):focus, .dark-style tag-input .ng2-tag-input tag:not(.readonly):not(.tag--editing):active {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.dark-style tag-input .ng2-tag-input tag path {
  fill: #fff !important;
}

.dark-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(.readonly):not(.tag--editing):focus,
.dark-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(.readonly):not(.tag--editing):active,
.dark-style tag-input .ng2-tag-input.ng2-tag-input--disabled tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.dark-style tag-input .ng2-tag-input__text-input::placeholder {
  color: #7c7e82;
}

.dark-style .is-valid tag-input .ng2-tag-input:not(.ng2-tag-input--focused),
.dark-style tag-input.is-valid .ng2-tag-input:not(.ng2-tag-input--focused) {
  border-color: #02BC77 !important;
}

.dark-style .is-invalid tag-input .ng2-tag-input:not(.ng2-tag-input--focused),
.dark-style tag-input.is-invalid .ng2-tag-input:not(.ng2-tag-input--focused) {
  border-color: #d9534f !important;
}

.dark-style .ngx-chips-secondary .ng2-tag-input tag {
  background-color: #8897AA !important;
  color: #fff !important;
}

.dark-style .ngx-chips-secondary .ng2-tag-input tag:focus, .dark-style .ngx-chips-secondary .ng2-tag-input tag:active {
  background-color: #66788f !important;
  color: #fff !important;
}

.dark-style .ngx-chips-secondary .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.dark-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-secondary .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #8897AA !important;
  color: #fff !important;
}

.dark-style .ngx-chips-success .ng2-tag-input tag {
  background-color: #02BC77 !important;
  color: #fff !important;
}

.dark-style .ngx-chips-success .ng2-tag-input tag:focus, .dark-style .ngx-chips-success .ng2-tag-input tag:active {
  background-color: #017f51 !important;
  color: #fff !important;
}

.dark-style .ngx-chips-success .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.dark-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-success .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #02BC77 !important;
  color: #fff !important;
}

.dark-style .ngx-chips-info .ng2-tag-input tag {
  background-color: #28c3d7 !important;
  color: #fff !important;
}

.dark-style .ngx-chips-info .ng2-tag-input tag:focus, .dark-style .ngx-chips-info .ng2-tag-input tag:active {
  background-color: #1e94a3 !important;
  color: #fff !important;
}

.dark-style .ngx-chips-info .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.dark-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-info .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #28c3d7 !important;
  color: #fff !important;
}

.dark-style .ngx-chips-warning .ng2-tag-input tag {
  background-color: #FFD950 !important;
  color: #665720 !important;
}

.dark-style .ngx-chips-warning .ng2-tag-input tag:focus, .dark-style .ngx-chips-warning .ng2-tag-input tag:active {
  background-color: #ffcc13 !important;
  color: #665720 !important;
}

.dark-style .ngx-chips-warning .ng2-tag-input tag delete-icon svg path {
  fill: #665720 !important;
}

.dark-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-warning .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #FFD950 !important;
  color: #665720 !important;
}

.dark-style .ngx-chips-danger .ng2-tag-input tag {
  background-color: #d9534f !important;
  color: #fff !important;
}

.dark-style .ngx-chips-danger .ng2-tag-input tag:focus, .dark-style .ngx-chips-danger .ng2-tag-input tag:active {
  background-color: #c12e2a !important;
  color: #fff !important;
}

.dark-style .ngx-chips-danger .ng2-tag-input tag delete-icon svg path {
  fill: #fff !important;
}

.dark-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-danger .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #d9534f !important;
  color: #fff !important;
}

.dark-style .ngx-chips-light .ng2-tag-input tag {
  background-color: rgba(255, 255, 255, 0.06) !important;
  color: #666666 !important;
}

.dark-style .ngx-chips-light .ng2-tag-input tag:focus, .dark-style .ngx-chips-light .ng2-tag-input tag:active {
  background-color: rgba(224, 224, 224, 0.06) !important;
  color: #666666 !important;
}

.dark-style .ngx-chips-light .ng2-tag-input tag delete-icon svg path {
  fill: #666666 !important;
}

.dark-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-light .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: rgba(255, 255, 255, 0.06) !important;
  color: #666666 !important;
}

.dark-style .ngx-chips-dark .ng2-tag-input tag {
  background-color: #fff !important;
  color: #666666 !important;
}

.dark-style .ngx-chips-dark .ng2-tag-input tag:focus, .dark-style .ngx-chips-dark .ng2-tag-input tag:active {
  background-color: #e0e0e0 !important;
  color: #666666 !important;
}

.dark-style .ngx-chips-dark .ng2-tag-input tag delete-icon svg path {
  fill: #666666 !important;
}

.dark-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag,
.dark-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag:focus,
.dark-style .ngx-chips-dark .ng2-tag-input.ng2-tag-input--disabled tag:active {
  background-color: #fff !important;
  color: #666666 !important;
}

.dark-style[dir=rtl] tag-input .ng2-tag-input tag,
.dark-style[dir=rtl] tag-input .ng2-tag-input .ng2-tag-input__text-input {
  margin: 0.375rem 0 0 0.375rem;
}

ng2-dropdown-menu .ng2-dropdown-menu[class] {
  max-height: 250px;
  width: auto;
  background-clip: padding-box;
}

ng2-dropdown-menu .ng2-dropdown-menu[class] .ng2-menu-item {
  overflow: hidden;
  height: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: normal;
}

.light-style .ng2-dropdown-menu[class] {
  z-index: 1060;
  padding: 0.3125rem 0;
  min-width: 10rem;
  border: 1px solid rgba(24, 28, 33, 0.05);
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
}

.light-style .ng2-dropdown-menu[class] .ng2-menu-item {
  background-color: #fff;
  padding: 0.438rem 1.25rem;
  color: #4E5155;
  font-size: 0.894rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.54;
}

.light-style .ng2-dropdown-menu[class] .ng2-menu-item--selected {
  background: rgba(24, 28, 33, 0.03);
  color: #4E5155;
}

.light-style .ng2-dropdown-menu[class] .ng2-menu-item:active {
  background: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.material-style .ng2-dropdown-menu[class] {
  z-index: 1060;
  padding: 0.3125rem 0;
  min-width: 10rem;
  border: 0;
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.07), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.03);
}

.material-style .ng2-dropdown-menu[class] .ng2-menu-item {
  background-color: #fff;
  padding: 0.5rem 1.25rem;
  color: #4E5155;
  font-size: 0.894rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.54;
}

.material-style .ng2-dropdown-menu[class] .ng2-menu-item--selected {
  background: rgba(24, 28, 33, 0.03);
  color: #4E5155;
}

.material-style .ng2-dropdown-menu[class] .ng2-menu-item:active {
  background: rgba(24, 28, 33, 0.1);
  color: #4E5155;
}

.dark-style .ng2-dropdown-menu[class] {
  z-index: 1060;
  padding: 0.3125rem 0;
  min-width: 10rem;
  border: 1px solid rgba(255, 255, 255, 0.09);
  background-color: #3b3e43;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
}

.dark-style .ng2-dropdown-menu[class] .ng2-menu-item {
  padding: 0.438rem 1.25rem;
  color: #fff;
  font-size: 0.894rem;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.54;
  background-color: #3b3e43;
}

.dark-style .ng2-dropdown-menu[class] .ng2-menu-item--selected {
  background: rgba(255, 255, 255, 0.06);
  color: #fff;
}

.dark-style .ng2-dropdown-menu[class] .ng2-menu-item:active {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.block-ui-white .block-ui-wrapper {
  background: rgba(255, 255, 255, 0.8);
  color: #444;
  animation: block-ui-animation .3s;
}

.block-ui-white .block-ui-template {
  top: 50%;
}

.dark-style .block-ui-white .block-ui-wrapper {
  background: rgba(34, 37, 43, 0.8);
  color: #fff;
}

@keyframes block-ui-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Config */
:root {
  --sk-size: 40px;
  --sk-color: #333;
}

/* Utility class for centering */
.sk-center {
  margin: auto;
}

/*  Plane

      <div class="sk-plane"></div>
 */
.sk-plane {
  width: var(--sk-size);
  height: var(--sk-size);
  background-color: var(--sk-color);
  animation: sk-plane 1.2s infinite ease-in-out;
}

@keyframes sk-plane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*  Chase

      <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
 */
.sk-chase {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--sk-color);
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%, 100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%, 0% {
    transform: scale(1);
  }
}

/*  Bounce

      <div class="sk-bounce">
        <div class="sk-bounce-dot"></div>
        <div class="sk-bounce-dot"></div>
      </div>
  */
.sk-bounce {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-bounce-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--sk-color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sk-bounce-dot:nth-child(2) {
  animation-delay: -1.0s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  45%, 55% {
    transform: scale(1);
  }
}

/*  Wave

      <div class="sk-wave">
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
      </div>
 */
.sk-wave {
  width: var(--sk-size);
  height: var(--sk-size);
  display: flex;
  justify-content: space-between;
}

.sk-wave-rect {
  background-color: var(--sk-color);
  height: 100%;
  width: 15%;
  animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) {
  animation-delay: -1.2s;
}

.sk-wave-rect:nth-child(2) {
  animation-delay: -1.1s;
}

.sk-wave-rect:nth-child(3) {
  animation-delay: -1.0s;
}

.sk-wave-rect:nth-child(4) {
  animation-delay: -0.9s;
}

.sk-wave-rect:nth-child(5) {
  animation-delay: -0.8s;
}

@keyframes sk-wave {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

/*  Pulse

      <div class="sk-pulse"></div>
 */
.sk-pulse {
  width: var(--sk-size);
  height: var(--sk-size);
  background-color: var(--sk-color);
  border-radius: 100%;
  animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@keyframes sk-pulse {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/*  Flow

      <div class="sk-flow">
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
      </div>
 */
.sk-flow {
  width: calc(var(--sk-size) * 1.3);
  height: calc(var(--sk-size) * 1.3);
  display: flex;
  justify-content: space-between;
}

.sk-flow-dot {
  width: 25%;
  height: 25%;
  background-color: var(--sk-color);
  border-radius: 50%;
  animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
}

.sk-flow-dot:nth-child(1) {
  animation-delay: -0.30s;
}

.sk-flow-dot:nth-child(2) {
  animation-delay: -0.15s;
}

@keyframes sk-flow {
  0%, 80%, 100% {
    transform: scale(0.3);
  }
  40% {
    transform: scale(1);
  }
}

/*  Swing

      <div class="sk-swing">
        <div class="sk-swing-dot"></div>
        <div class="sk-swing-dot"></div>
      </div>
 */
.sk-swing {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  animation: sk-swing 1.8s infinite linear;
}

.sk-swing-dot {
  width: 45%;
  height: 45%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--sk-color);
  border-radius: 100%;
  animation: sk-swing-dot 2s infinite ease-in-out;
}

.sk-swing-dot:nth-child(2) {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes sk-swing {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-swing-dot {
  0%, 100% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1);
  }
}

/*  Circle

      <div class="sk-circle">
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
      </div>
 */
.sk-circle {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-circle-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-dot:before {
  content: '';
  display: block;
  width: 15%;
  height: 15%;
  background-color: var(--sk-color);
  border-radius: 100%;
  animation: sk-circle 1.2s infinite ease-in-out both;
}

.sk-circle-dot:nth-child(1) {
  transform: rotate(30deg);
}

.sk-circle-dot:nth-child(2) {
  transform: rotate(60deg);
}

.sk-circle-dot:nth-child(3) {
  transform: rotate(90deg);
}

.sk-circle-dot:nth-child(4) {
  transform: rotate(120deg);
}

.sk-circle-dot:nth-child(5) {
  transform: rotate(150deg);
}

.sk-circle-dot:nth-child(6) {
  transform: rotate(180deg);
}

.sk-circle-dot:nth-child(7) {
  transform: rotate(210deg);
}

.sk-circle-dot:nth-child(8) {
  transform: rotate(240deg);
}

.sk-circle-dot:nth-child(9) {
  transform: rotate(270deg);
}

.sk-circle-dot:nth-child(10) {
  transform: rotate(300deg);
}

.sk-circle-dot:nth-child(11) {
  transform: rotate(330deg);
}

.sk-circle-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-circle-dot:nth-child(2):before {
  animation-delay: -1s;
}

.sk-circle-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-circle-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-circle-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-circle-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.sk-circle-dot:nth-child(7):before {
  animation-delay: -0.5s;
}

.sk-circle-dot:nth-child(8):before {
  animation-delay: -0.4s;
}

.sk-circle-dot:nth-child(9):before {
  animation-delay: -0.3s;
}

.sk-circle-dot:nth-child(10):before {
  animation-delay: -0.2s;
}

.sk-circle-dot:nth-child(11):before {
  animation-delay: -0.1s;
}

@keyframes sk-circle {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/*  Circle Fade

      <div class="sk-circle-fade">
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
      </div>
 */
.sk-circle-fade {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-circle-fade-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-fade-dot:before {
  content: '';
  display: block;
  width: 15%;
  height: 15%;
  background-color: var(--sk-color);
  border-radius: 100%;
  animation: sk-circle-fade 1.2s infinite ease-in-out both;
}

.sk-circle-fade-dot:nth-child(1) {
  transform: rotate(30deg);
}

.sk-circle-fade-dot:nth-child(2) {
  transform: rotate(60deg);
}

.sk-circle-fade-dot:nth-child(3) {
  transform: rotate(90deg);
}

.sk-circle-fade-dot:nth-child(4) {
  transform: rotate(120deg);
}

.sk-circle-fade-dot:nth-child(5) {
  transform: rotate(150deg);
}

.sk-circle-fade-dot:nth-child(6) {
  transform: rotate(180deg);
}

.sk-circle-fade-dot:nth-child(7) {
  transform: rotate(210deg);
}

.sk-circle-fade-dot:nth-child(8) {
  transform: rotate(240deg);
}

.sk-circle-fade-dot:nth-child(9) {
  transform: rotate(270deg);
}

.sk-circle-fade-dot:nth-child(10) {
  transform: rotate(300deg);
}

.sk-circle-fade-dot:nth-child(11) {
  transform: rotate(330deg);
}

.sk-circle-fade-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-circle-fade-dot:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-circle-fade-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-circle-fade-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-circle-fade-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-circle-fade-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

.sk-circle-fade-dot:nth-child(7):before {
  animation-delay: -0.5s;
}

.sk-circle-fade-dot:nth-child(8):before {
  animation-delay: -0.4s;
}

.sk-circle-fade-dot:nth-child(9):before {
  animation-delay: -0.3s;
}

.sk-circle-fade-dot:nth-child(10):before {
  animation-delay: -0.2s;
}

.sk-circle-fade-dot:nth-child(11):before {
  animation-delay: -0.1s;
}

@keyframes sk-circle-fade {
  0%, 39%, 100% {
    opacity: 0;
    transform: scale(0.6);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
}

/*  Grid

    <div class="sk-grid">
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
    </div>
 */
.sk-grid {
  width: var(--sk-size);
  height: var(--sk-size);
  /* Cube positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */
}

.sk-grid-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: var(--sk-color);
  float: left;
  animation: sk-grid 1.3s infinite ease-in-out;
}

.sk-grid-cube:nth-child(1) {
  animation-delay: 0.2s;
}

.sk-grid-cube:nth-child(2) {
  animation-delay: 0.3s;
}

.sk-grid-cube:nth-child(3) {
  animation-delay: 0.4s;
}

.sk-grid-cube:nth-child(4) {
  animation-delay: 0.1s;
}

.sk-grid-cube:nth-child(5) {
  animation-delay: 0.2s;
}

.sk-grid-cube:nth-child(6) {
  animation-delay: 0.3s;
}

.sk-grid-cube:nth-child(7) {
  animation-delay: 0.0s;
}

.sk-grid-cube:nth-child(8) {
  animation-delay: 0.1s;
}

.sk-grid-cube:nth-child(9) {
  animation-delay: 0.2s;
}

@keyframes sk-grid {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

/*  Fold

      <div class="sk-fold">
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
      </div>
 */
.sk-fold {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  transform: rotateZ(45deg);
}

.sk-fold-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-fold-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--sk-color);
  animation: sk-fold 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.sk-fold-cube:nth-child(2) {
  transform: scale(1.1) rotateZ(90deg);
}

.sk-fold-cube:nth-child(4) {
  transform: scale(1.1) rotateZ(180deg);
}

.sk-fold-cube:nth-child(3) {
  transform: scale(1.1) rotateZ(270deg);
}

.sk-fold-cube:nth-child(2):before {
  animation-delay: 0.3s;
}

.sk-fold-cube:nth-child(4):before {
  animation-delay: 0.6s;
}

.sk-fold-cube:nth-child(3):before {
  animation-delay: 0.9s;
}

@keyframes sk-fold {
  0%, 10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/*  Wander

      <div class="sk-wander">
        <div class="sk-wander-cube"></div>
        <div class="sk-wander-cube"></div>
        <div class="sk-wander-cube"></div>
        <div class="sk-wander-cube"></div>
      </div>
 */
.sk-wander {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-wander-cube {
  background-color: var(--sk-color);
  width: 20%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  --sk-wander-distance: calc(var(--sk-size) * 0.75);
  animation: sk-wander 2.0s ease-in-out -2.0s infinite both;
}

.sk-wander-cube:nth-child(2) {
  animation-delay: -0.5s;
}

.sk-wander-cube:nth-child(3) {
  animation-delay: -1.0s;
}

@keyframes sk-wander {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
  }
  50% {
    /* Make FF rotate in the right direction */
    transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-179deg);
  }
  50.1% {
    transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-180deg);
  }
  75% {
    transform: translateX(0) translateY(var(--sk-wander-distance)) rotate(-270deg) scale(0.6);
  }
  100% {
    transform: rotate(-360deg);
  }
}

:root {
  --sk-size: 30px;
  --sk-color: #ccc;
}

.sk-wave {
  width: 40px;
  white-space: nowrap;
}

.swal2-popup.swal2-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.625em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 0.625em #d9d9d9;
}

.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
}

.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 0.6em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}

.swal2-popup.swal2-toast .swal2-close {
  position: static;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}

.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: .25em;
  }
}

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  top: .875em;
  width: 1.375em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: .3125em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: .3125em;
}

.swal2-popup.swal2-toast .swal2-actions {
  flex-basis: auto !important;
  width: auto;
  height: auto;
  margin: 0 .3125em;
}

.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 .3125em;
  padding: .3125em .625em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4);
}

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  top: -.25em;
  left: .9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: .4375em;
  width: .4375em;
  height: 2.6875em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'] {
  height: .3125em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 1.125em;
  left: .1875em;
  width: .75em;
}

.swal2-popup.swal2-toast .swal2-success [class^='swal2-success-line'][class$='long'] {
  top: .9375em;
  right: .1875em;
  width: 1.375em;
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip .75s;
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long .75s;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}

.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}

.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}

.swal2-container.swal2-backdrop-hide {
  background: transparent !important;
}

.swal2-container.swal2-top {
  align-items: flex-start;
}

.swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}

.swal2-container.swal2-center {
  align-items: center;
}

.swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
  align-items: center;
  justify-content: flex-start;
}

.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end;
}

.swal2-container.swal2-bottom {
  align-items: flex-end;
}

.swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}

.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.swal2-container.swal2-bottom > :first-child,
.swal2-container.swal2-bottom-start > :first-child,
.swal2-container.swal2-bottom-left > :first-child,
.swal2-container.swal2-bottom-end > :first-child,
.swal2-container.swal2-bottom-right > :first-child {
  margin-top: auto;
}

.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}

.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.swal2-container.swal2-grow-column {
  flex: 1;
  flex-direction: column;
}

.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}

.swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}

.swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}

.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}

.swal2-container.swal2-no-transition {
  transition: none !important;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
  margin: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}

.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}

.swal2-popup:focus {
  outline: none;
}

.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}

.swal2-actions {
  display: flex;
  z-index: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0;
}

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  box-sizing: border-box;
  width: 2.5em;
  height: 2.5em;
  margin: .46875em;
  padding: 0;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border: .25em solid transparent;
  border-radius: 100%;
  border-color: transparent;
  background-color: transparent !important;
  color: transparent !important;
  cursor: default;
  user-select: none;
}

.swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
  margin-right: 30px;
  margin-left: 30px;
}

.swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border: 3px solid #999999;
  border-radius: 50%;
  border-right-color: transparent;
  box-shadow: 1px 1px 1px #fff;
}

.swal2-styled {
  margin: .3125em;
  padding: .625em 2em;
  box-shadow: none;
  font-weight: 500;
}

.swal2-styled:not([disabled]) {
  cursor: pointer;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #3085d6;
  color: #fff;
  font-size: 1.0625em;
}

.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #aaa;
  color: #fff;
  font-size: 1.0625em;
}

.swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(50, 100, 150, 0.4);
}

.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.25em;
  overflow: hidden;
  border-bottom-right-radius: 0.3125em;
  border-bottom-left-radius: 0.3125em;
}

.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}

.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}

.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 0;
  background: transparent;
  color: #cccccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
}

.swal2-close:hover {
  transform: none;
  background: transparent;
  color: #f27474;
}

.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}

.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em auto;
}

.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  color: inherit;
  font-size: 1.125em;
}

.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}

.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 3px #c4e6f5;
}

.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #cccccc;
}

.swal2-range {
  margin: 1em auto;
  background: #fff;
}

.swal2-range input {
  width: 80%;
}

.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}

.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}

.swal2-input[type='number'] {
  max-width: 10em;
}

.swal2-file {
  background: inherit;
  font-size: 1.125em;
}

.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}

.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em;
}

.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}

.swal2-radio label,
.swal2-checkbox label {
  margin: 0 .6em;
  font-size: 1.125em;
}

.swal2-radio input,
.swal2-checkbox input {
  margin: 0 .4em;
}

.swal2-validation-message {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}

.swal2-validation-message::before {
  content: '!';
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: .25em solid transparent;
  border-radius: 50%;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}

.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}

.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: .3125em;
  border-radius: .125em;
  background-color: #f27474;
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='left'] {
  left: 1.0625em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^='swal2-x-mark-line'][class$='right'] {
  right: 1em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon .5s;
}

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark .5s;
}

.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}

.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}

.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}

.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='left'] {
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}

.swal2-icon.swal2-success [class^='swal2-success-circular-line'][class$='right'] {
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}

.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: .5em;
  left: 1.625em;
  width: .4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^='swal2-success-line'] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #a5dc86;
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='tip'] {
  top: 2.875em;
  left: .8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^='swal2-success-line'][class$='long'] {
  top: 2.375em;
  right: .5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip .75s;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long .75s;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}

.swal2-progress-steps {
  align-items: center;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600;
}

.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #3085d6;
  color: #fff;
  line-height: 2em;
  text-align: center;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #3085d6;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
  background: #3085d6;
}

[class^='swal2'] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: swal2-show 0.3s;
}

.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}

.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}

@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid rgba(50, 100, 150, 0.4);
  }
}

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}

@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0;
  }
  54% {
    top: .125em;
    left: .125em;
    width: 0;
  }
  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em;
  }
  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: .9375em;
    width: 0;
  }
  84% {
    top: .9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em;
  }
}

@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: .0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: .125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: .8125em;
    width: 1.5625em;
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}

body.swal2-height-auto {
  height: auto !important;
}

body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: transparent !important;
}

body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-top-start, body.swal2-no-backdrop .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
}

body.swal2-no-backdrop .swal2-container.swal2-top-end, body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}

body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.swal2-no-backdrop .swal2-container.swal2-center-start, body.swal2-no-backdrop .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-center-end, body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-bottom-start, body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
  bottom: 0;
  left: 0;
}

body.swal2-no-backdrop .swal2-container.swal2-bottom-end, body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  right: 0;
  bottom: 0;
}

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden='true'] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}

body.swal2-toast-shown .swal2-container {
  background-color: transparent;
}

body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}

body.swal2-toast-column .swal2-toast {
  flex-direction: column;
  align-items: stretch;
}

body.swal2-toast-column .swal2-toast .swal2-actions {
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: .3125em;
}

body.swal2-toast-column .swal2-toast .swal2-loading {
  justify-content: center;
}

body.swal2-toast-column .swal2-toast .swal2-input {
  height: 2em;
  margin: .3125em auto;
  font-size: 1em;
}

body.swal2-toast-column .swal2-toast .swal2-validation-message {
  font-size: 1em;
}

.swal2-modal.swal2-popup .swal2-title {
  margin: 1.875rem 0 1rem 0;
}

.swal2-modal.swal2-popup .swal2-content {
  margin: 0 0 1rem 0;
}

.swal2-modal.swal2-popup .swal2-actions {
  margin-top: 1rem;
}

.swal2-modal.swal2-popup .swal2-actions .btn {
  align-items: center;
}

.swal2-modal.swal2-popup .swal2-actions button + button {
  margin-left: .375rem;
}

[dir=rtl] .swal2-modal.swal2-popup .swal2-actions button + button {
  margin-right: .375rem;
  margin-left: 0;
}

.swal2-modal.swal2-popup .swal2-input,
.swal2-modal.swal2-popup .swal2-file,
.swal2-modal.swal2-popup .swal2-textarea,
.swal2-modal.swal2-popup .swal2-select,
.swal2-modal.swal2-popup .swal2-radio,
.swal2-modal.swal2-popup .swal2-checkbox {
  margin: 1.875rem 0;
}

.swal2-modal.swal2-popup .swal2-input,
.swal2-modal.swal2-popup .swal2-file,
.swal2-modal.swal2-popup .swal2-textarea {
  box-shadow: none !important;
}

.swal2-modal.swal2-popup .swal2-checkbox input,
.swal2-modal.swal2-popup .swal2-radio input {
  margin-right: .375rem;
}

[dir=rtl] .swal2-modal.swal2-popup .swal2-checkbox input, [dir=rtl]
.swal2-modal.swal2-popup .swal2-radio input {
  margin-right: 0;
  margin-left: .375rem;
}

.swal2-modal.swal2-popup .swal2-icon {
  margin-bottom: 0;
}

[dir=rtl] .swal2-modal.swal2-popup .swal2-icon {
  transform: scaleX(-1);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-modal:not([style="display: none;"]),
  .swal2-icon:not([style="display: none;"]),
  .swal2-actions:not([style="display: none;"]),
  .swal2-image:not([style="display: none;"]),
  .swal2-input:not([style="display: none;"]),
  .swal2-file:not([style="display: none;"]),
  .swal2-range:not([style="display: none;"]),
  .swal2-select:not([style="display: none;"]),
  .swal2-radio:not([style="display: none;"]),
  .swal2-checkbox:not([style="display: none;"]),
  .swal2-textarea:not([style="display: none;"]),
  .swal2-footer:not([style="display: none;"]) {
    display: flex;
  }
}

[dir=rtl] .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  margin-right: .3125rem;
  margin-left: 0;
}

[dir=rtl] .swal2-close {
  right: auto;
  left: .5rem;
}

[dir=rtl] .swal2-range input {
  float: right;
}

[dir=rtl] .swal2-range output {
  float: left;
}

[dir=rtl] .swal2-radio label:not(:first-child) {
  margin-right: 1.25rem;
  margin-left: 0;
}

[dir=rtl] .swal2-validationerror::before {
  margin-right: 0;
  margin-left: .625rem;
}

.swal2-popup .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  display: block;
  width: 1em;
  height: 1em;
  margin-left: .5em;
  border: .15em solid currentColor;
  border-right-color: transparent;
  box-shadow: none;
}

[dir=rtl] .swal2-popup .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
  margin-left: 0;
  margin-right: .5em;
}

.light-style .swal2-container {
  z-index: 1090;
}

.light-style .swal2-container .popover {
  z-index: 1091;
}

.light-style .swal2-container .tooltip {
  z-index: 1092;
}

.light-style .swal2-container.swal2-shown {
  background: rgba(24, 28, 33, 0.5);
}

.light-style .swal2-modal.swal2-popup {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: #fff;
  border-radius: 0.25rem;
}

.light-style .swal2-popup .swal2-title {
  color: #4E5155;
  font-weight: 500;
  font-size: 1.813rem;
}

.light-style .swal2-html-container {
  font-size: smaller;
}

.light-style .swal2-popup .swal2-content {
  color: #a3a4a6;
  font-weight: 300;
  font-size: 1.1175rem;
  line-height: 1.47;
}

.light-style .swal2-popup .swal2-footer {
  border-top-color: rgba(24, 28, 33, 0.06);
}

.light-style .swal2-popup .swal2-input,
.light-style .swal2-popup .swal2-file,
.light-style .swal2-popup .swal2-textarea {
  border: 1px solid rgba(24, 28, 33, 0.1) !important;
  color: #4E5155;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.light-style .swal2-popup .swal2-validationerror {
  background: rgba(24, 28, 33, 0.06);
  color: #4E5155;
}

.light-style .swal2-popup .swal2-icon.swal2-success {
  border-color: #02BC77;
}

.light-style .swal2-popup .swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(2, 188, 119, 0.2);
}

.light-style .swal2-popup .swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #02BC77;
}

.light-style .swal2-popup .swal2-icon.swal2-question {
  border-color: rgba(136, 151, 170, 0.4);
  color: #8897AA;
}

.light-style .swal2-popup .swal2-icon.swal2-info {
  border-color: rgba(40, 195, 215, 0.4);
  color: #28c3d7;
}

.light-style .swal2-popup .swal2-icon.swal2-warning {
  border-color: rgba(255, 217, 80, 0.8);
  color: #FFD950;
}

.light-style .swal2-popup .swal2-icon.swal2-error {
  border-color: rgba(217, 83, 79, 0.6);
}

.light-style .swal2-popup .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  border-color: #d9534f;
}

.material-style .swal2-container {
  z-index: 1090;
}

.material-style .swal2-container .popover {
  z-index: 1091;
}

.material-style .swal2-container .tooltip {
  z-index: 1092;
}

.material-style .swal2-container.swal2-shown {
  background: rgba(24, 28, 33, 0.2);
}

.material-style .swal2-modal.swal2-popup {
  border-radius: 0;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.1), 0px 9px 46px 8px rgba(0, 0, 0, 0.08);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: #fff;
}

.material-style .swal2-popup .swal2-title {
  color: #4E5155;
  font-weight: 500;
  font-size: 1.813rem;
}

.material-style .swal2-popup .swal2-content {
  color: #a3a4a6;
  font-weight: 300;
  font-size: 1.1175rem;
  line-height: 1.47;
}

.material-style .swal2-popup .swal2-footer {
  border-top-color: rgba(24, 28, 33, 0.06);
}

.material-style .swal2-popup .swal2-input,
.material-style .swal2-popup .swal2-file,
.material-style .swal2-popup .swal2-textarea {
  padding-right: 0 !important;
  padding-left: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid rgba(24, 28, 33, 0.1) !important;
  border-radius: 0;
  color: #4E5155;
  font-size: 1rem;
}

.material-style .swal2-popup .swal2-validationerror {
  background: rgba(24, 28, 33, 0.06);
  color: #4E5155;
}

.material-style .swal2-popup .swal2-icon.swal2-success {
  border-color: #02BC77;
}

.material-style .swal2-popup .swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(2, 188, 119, 0.2);
}

.material-style .swal2-popup .swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #02BC77;
}

.material-style .swal2-popup .swal2-icon.swal2-question {
  border-color: rgba(136, 151, 170, 0.4);
  color: #8897AA;
}

.material-style .swal2-popup .swal2-icon.swal2-info {
  border-color: rgba(40, 195, 215, 0.4);
  color: #28c3d7;
}

.material-style .swal2-popup .swal2-icon.swal2-warning {
  border-color: rgba(255, 217, 80, 0.8);
  color: #FFD950;
}

.material-style .swal2-popup .swal2-icon.swal2-error {
  border-color: rgba(217, 83, 79, 0.6);
}

.material-style .swal2-popup .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  border-color: #d9534f;
}

.dark-style .swal2-container {
  z-index: 1090;
}

.dark-style .swal2-container .popover {
  z-index: 1091;
}

.dark-style .swal2-container .tooltip {
  z-index: 1092;
}

.dark-style .swal2-container.swal2-shown {
  background: rgba(37, 40, 46, 0.5);
}

.dark-style .swal2-modal.swal2-popup {
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: #32353b;
  border-radius: 0.25rem;
}

.dark-style .swal2-popup .swal2-title {
  color: #fff;
  font-weight: 500;
  font-size: 1.813rem;
}

.dark-style .swal2-popup .swal2-content {
  color: #a8a9ab;
  font-weight: 300;
  font-size: 1.1175rem;
  line-height: 1.47;
}

.dark-style .swal2-popup .swal2-footer {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-style .swal2-popup .swal2-input,
.dark-style .swal2-popup .swal2-file,
.dark-style .swal2-popup .swal2-textarea {
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.dark-style .swal2-popup .swal2-validationerror {
  background: rgba(255, 255, 255, 0.06);
  color: #fff;
}

.dark-style .swal2-popup .swal2-icon.swal2-success {
  border-color: #02BC77;
}

.dark-style .swal2-popup .swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(2, 188, 119, 0.2);
}

.dark-style .swal2-popup .swal2-icon.swal2-success [class^='swal2-success-line'] {
  background-color: #02BC77;
}

.dark-style .swal2-popup .swal2-icon.swal2-question {
  border-color: rgba(136, 151, 170, 0.4);
  color: #8897AA;
}

.dark-style .swal2-popup .swal2-icon.swal2-info {
  border-color: rgba(40, 195, 215, 0.4);
  color: #28c3d7;
}

.dark-style .swal2-popup .swal2-icon.swal2-warning {
  border-color: rgba(255, 217, 80, 0.8);
  color: #FFD950;
}

.dark-style .swal2-popup .swal2-icon.swal2-error {
  border-color: rgba(217, 83, 79, 0.6);
}

.dark-style .swal2-popup .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
  border-color: #d9534f;
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
  font-variant-numeric: tabular-nums;
  font-weight: 500;
  height: 100%;
  line-height: 1.7;
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}

.plyr video,
.plyr audio,
.plyr iframe {
  display: block;
  height: 100%;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *,
.plyr--full-ui *::after,
.plyr--full-ui *::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5764;
  border-radius: 2px;
  color: #fff;
  font-size: 9px;
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  color: #fff;
  display: none;
  font-size: 14px;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 2px;
  box-decoration-break: clone;
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__captions .plyr__caption div {
  display: inline;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 16px;
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
}

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
  outline: 0;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control::after, a.plyr__control::before {
  display: none;
}

.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child,
.plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
  padding-left: 0;
}

.plyr__controls:empty {
  display: none;
}

.plyr [data-plyr='captions'],
.plyr [data-plyr='pip'],
.plyr [data-plyr='airplay'],
.plyr [data-plyr='fullscreen'] {
  display: none;
}

.plyr--captions-enabled [data-plyr='captions'],
.plyr--pip-supported [data-plyr='pip'],
.plyr--airplay-supported [data-plyr='airplay'],
.plyr--fullscreen-enabled [data-plyr='fullscreen'] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded='true'] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded='true'] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5764;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container::after {
  border: 4px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  content: '';
  height: 0;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 0;
}

.plyr__menu__container [role='menu'] {
  padding: 7px;
}

.plyr__menu__container [role='menuitem'],
.plyr__menu__container [role='menuitemradio'] {
  margin-top: 2px;
}

.plyr__menu__container [role='menuitem']:first-child,
.plyr__menu__container [role='menuitemradio']:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5764;
  display: flex;
  font-size: 14px;
  padding: 4px 11px;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward::after {
  border-left-color: rgba(74, 87, 100, 0.8);
  right: 5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 500;
  margin: 7px;
  margin-bottom: 3px;
  padding-left: 28px;
  position: relative;
  width: calc(100% - 14px);
}

.plyr__menu__container .plyr__control--back::after {
  border-right-color: rgba(74, 87, 100, 0.8);
  left: 7px;
}

.plyr__menu__container .plyr__control--back::before {
  background: rgba(114, 133, 151, 0.2);
  box-shadow: 0 1px 0 #fff;
  content: '';
  height: 1px;
  left: 0;
  margin-top: 4px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role='menuitemradio'] {
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role='menuitemradio']::before, .plyr__menu__container .plyr__control[role='menuitemradio']::after {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role='menuitemradio']::before {
  background: rgba(0, 0, 0, 0.1);
  content: '';
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role='menuitemradio']::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::before {
  background: #00b3ff;
}

.plyr__menu__container .plyr__control[role='menuitemradio'][aria-checked='true']::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role='menuitemradio'].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role='menuitemradio']:hover::before {
  background: rgba(0, 0, 0, 0.1);
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  overflow: hidden;
  padding-left: 25px;
  pointer-events: none;
}

.plyr--full-ui input[type='range'] {
  -webkit-appearance: none;
  /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 26px;
  color: #00b3ff;
  display: block;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type='range']::-webkit-slider-runnable-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%));
}

.plyr--full-ui input[type='range']::-webkit-slider-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
  height: 13px;
  position: relative;
  transition: all 0.2s ease;
  width: 13px;
  -webkit-appearance: none;
  /* stylelint-disable-line */
  margin-top: -4px;
}

.plyr--full-ui input[type='range']::-moz-range-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type='range']::-moz-range-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
  height: 13px;
  position: relative;
  transition: all 0.2s ease;
  width: 13px;
}

.plyr--full-ui input[type='range']::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  height: 5px;
}

.plyr--full-ui input[type='range']::-ms-track {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  user-select: none;
  color: transparent;
}

.plyr--full-ui input[type='range']::-ms-fill-upper {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  user-select: none;
}

.plyr--full-ui input[type='range']::-ms-fill-lower {
  background: transparent;
  border: 0;
  border-radius: 2.5px;
  height: 5px;
  transition: box-shadow 0.3s ease;
  user-select: none;
  background: currentColor;
}

.plyr--full-ui input[type='range']::-ms-thumb {
  background: #fff;
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2);
  height: 13px;
  position: relative;
  transition: all 0.2s ease;
  width: 13px;
  margin-top: 0;
}

.plyr--full-ui input[type='range']::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type='range']:focus {
  outline: 0;
}

.plyr--full-ui input[type='range']::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-webkit-slider-runnable-track {
  box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
  outline: 0;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-moz-range-track {
  box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
  outline: 0;
}

.plyr--full-ui input[type='range'].plyr__tab-focus::-ms-track {
  box-shadow: 0 0 0 5px rgba(0, 179, 255, 0.5);
  outline: 0;
}

.plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 14px;
}

.plyr__time + .plyr__time::before {
  content: '\2044';
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  color: #4a5764;
  font-size: 14px;
  font-weight: 500;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  opacity: 0;
  padding: 5px 7.5px;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1);
}

.plyr__progress {
  left: 6.5px;
  margin-right: 13px;
  position: relative;
}

.plyr__progress input[type='range'], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-right: -6.5px;
  width: calc(100% + 13px);
}

.plyr__progress input[type='range'] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: 5px;
  left: 0;
  margin-top: -2.5px;
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: transparent;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 41, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 41, 47, 0.6) 50%, rgba(35, 41, 47, 0.6) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 201, 209, 0.66);
}

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}

.plyr__volume input[type='range'] {
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  z-index: 2;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  border-radius: inherit;
  color: #4a5764;
  padding: 10px;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded='true'] {
  background: #00b3ff;
  color: #fff;
}

.plyr--full-ui.plyr--audio input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(193, 201, 209, 0.66);
}

.plyr--full-ui.plyr--audio input[type='range']::-moz-range-track {
  background-color: rgba(193, 201, 209, 0.66);
}

.plyr--full-ui.plyr--audio input[type='range']::-ms-track {
  background-color: rgba(193, 201, 209, 0.66);
}

.plyr--full-ui.plyr--audio input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--full-ui.plyr--audio input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.plyr--audio .plyr__progress__buffer {
  color: rgba(193, 201, 209, 0.66);
}

.plyr--video {
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%;
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 20px 5px 5px;
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px;
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded='true'] {
  background: #00b3ff;
  color: #fff;
}

.plyr__control--overlaid {
  background: rgba(0, 179, 255, 0.8);
  border: 0;
  border-radius: 100%;
  color: #fff;
  display: none;
  left: 50%;
  padding: 15px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  background: #00b3ff;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type='range']::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type='range']::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type='range']::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
}

.plyr--full-ui.plyr--video input[type='range']:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type='range']:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--full-ui.plyr--video input[type='range']:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(35, 41, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
}

.plyr--video .plyr__progress__buffer {
  color: rgba(255, 255, 255, 0.25);
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
  }
}

/* stylelint-disable-next-line */
.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}

/* stylelint-disable-next-line */
.plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}

/* stylelint-disable-next-line */
.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  position: static;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads::after {
  background: rgba(35, 41, 47, 0.8);
  border-radius: 2px;
  bottom: 10px;
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  z-index: 3;
}

.plyr__ads::after:empty {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  left: 0;
  margin: -2.5px 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}

.plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  bottom: -4px;
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr__preview-thumb__image-container {
  background: #c1c9d1;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3;
}

.plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  padding: 3px 6px;
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

@media (min-width: 720px) {
  ngb-carousel,
  .dropdown-item,
  ngb-datepicker,
  ngb-rating {
    outline: 0 !important;
  }
}

.layout-wrapper {
  overflow: hidden;
}

.router-transitions {
  opacity: 1;
  transition: opacity 0.2s;
}

body.app-loading .router-transitions {
  opacity: 0.75;
}

@-webkit-keyframes ngFloatingElementAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes ngFloatingElementAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ngFloatingElementAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html .tooltip,
html .popover {
  animation: ngFloatingElementAnimation 0.25s;
}

html .dropdown-menu {
  animation: ngFloatingElementAnimation 0.1s;
}

@-webkit-keyframes ngModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@-moz-keyframes ngModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes ngModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@-webkit-keyframes ngDefaultModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@-moz-keyframes ngDefaultModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes ngDefaultModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(150px) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@-webkit-keyframes ngTopModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes ngTopModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes ngTopModalAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes ngSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes ngSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ngSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes ngRtlSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes ngRtlSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ngRtlSlideModalAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes ngFillInModalAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@-moz-keyframes ngFillInModalAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes ngFillInModalAnimation {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

ngb-modal-backdrop.modal-backdrop {
  opacity: 0.5;
  animation: ngModalBackdropAnimation 0.15s ease-out;
}

ngb-modal-window.modal .modal-dialog {
  animation: ngDefaultModalAnimation 0.15s ease-out;
}

ngb-modal-window.modal-top .modal-dialog {
  animation: ngTopModalAnimation 0.15s ease-out;
}

ngb-modal-window.modal-slide .modal-dialog {
  animation: ngSlideModalAnimation 0.15s ease-out;
}

ngb-modal-window.modal-fill-in .modal-dialog {
  animation: ngFillInModalAnimation 0.15s ease-out;
}

[dir=rtl] ngb-modal-window.modal-slide .modal-dialog {
  animation: ngRtlSlideModalAnimation 0.15s ease-out;
}

@-webkit-keyframes ngMaterialModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

@-moz-keyframes ngMaterialModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes ngMaterialModalBackdropAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

.material-style ngb-modal-backdrop.modal-backdrop {
  opacity: 0.2;
  animation: ngMaterialModalBackdropAnimation 0.15s ease-out;
}

[dir=rtl] .dz-hidden-input {
  right: 0 !important;
  left: auto !important;
}

ngb-accordion .card-header .btn.btn-link {
  width: 100%;
  padding: 0;
  text-align: inherit;
  white-space: normal;
  border: 0;
  font-size: 0.894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
  display: flex;
  justify-content: space-between;
}

ngb-typeahead-window .dropdown-item {
  cursor: pointer;
}

.table-fixed-header {
  position: relative;
  overflow: auto;
}

.table-fixed-header > .table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-fixed-header > .table td + td,
.table-fixed-header > .table th + td,
.table-fixed-header > .table th + th {
  border-left: 0 !important;
}

.table-fixed-header > .table tr + tr:not(:last-child) th,
.table-fixed-header > .table tr + tr:not(:last-child) td,
.table-fixed-header > .table tbody tr:first-child th,
.table-fixed-header > .table tbody tr:first-child td {
  border-bottom: 0 !important;
}

.table-fixed-header > .table tbody tr:first-child th,
.table-fixed-header > .table tbody tr:first-child td {
  border-top: 0 !important;
}

.table-fixed-header > .table > thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

.sticky {
  position: fixed;
  top: 67px;
  background-color: white;
  margin-right: 30px;
}

.ui-wh-30 {
  width: 30px !important;
  height: 30px !important;
}

.dashed {
  width: 80px !important;
  height: 80px !important;
  border: 3px dashed rgba(24, 28, 33, 0.1);
}

.nested-modal {
  z-index: 1050 !important;
}

.icon-space {
  margin-right: 1.5vmin;
}

.card-body-2 {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

#theme-settings {
  display: none !important;
}

.light-style .swal2-popup .swal2-icon.swal2-question.swal-custom-primary {
  border-color: #ffc14f !important;
  color: #ffc14f !important;
}

.light-style .swal2-popup .swal2-icon.swal2-info.swal-custom-primary {
  border-color: #ffc14f !important;
  color: #ffc14f !important;
}

.light-style .swal2-popup .swal2-icon.swal2-error.swal-custom-primary {
  border-color: #ffc14f !important;
}

.light-style .swal2-popup .swal2-icon.swal2-error.swal-custom-primary [class^='swal2-x-mark-line'] {
  border-color: #ffc14f;
  background-color: #ffc14f;
}

.light-style .swal2-popup .swal2-icon.swal2-success.swal-custom-primary {
  border-color: #ffc14f !important;
  color: #ffc14f !important;
}

.light-style .swal2-popup .swal2-icon.swal2-success.swal-custom-primary [class^='swal2-success-line'] {
  background-color: #ffc14f !important;
}

.light-style .swal2-popup .swal2-icon.swal2-success.swal-custom-primary .swal2-success-ring {
  border-color: #ffc14f !important;
}

.light-style .swal2-popup .swal2-close.swal-custom-primary:focus {
  outline: none;
}

.light-style .swal2-popup .swal2-content.swal-custom-primary {
  margin: 1.25rem 1rem 1.25rem 1rem;
  display: flex;
  white-space: pre-line;
  -ms-flex-pack: center;
  justify-content: initial;
  font-size: 1.25rem;
}

.light-style .swal2-popup .swal2-title.swal-custom-primary {
  margin: 1.875rem 0 0.5rem 0;
  font-size: 1.5rem;
}

.light-style .swal2-popup .swal2-actions.swal-custom-primary {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
  white-space: nowrap;
}

@media (min-width: 256px) {
  .swal-button-offset-padding {
    width: 12rem;
  }
}

@media (min-width: 375px) {
  .swal-button-offset-padding {
    width: 12rem;
  }
}

@media (min-width: 576px) {
  .swal-button-offset-padding {
    width: 9rem;
  }
}

@media (min-width: 768px) {
  .swal-button-offset-padding {
    width: 12rem;
  }
}

@media (min-width: 992px) {
  .swal-button-offset-padding {
    width: 12rem;
  }
}

@media (min-width: 1200px) {
  .swal-button-offset-padding {
    width: 11rem;
    padding: 10px;
  }
}

@media (min-width: 1444px) {
  .swal-button-offset-padding {
    width: 12rem;
    padding: 10px;
  }
}

.navbar-nav .dropdown-menu {
  position: absolute;
  float: none;
}

.custom-modal {
  min-height: 75vh;
}

.custom-modal .modal-body {
  padding-bottom: 1.25rem;
}

.custom-modal .btn-primary.disabled,
.custom-modal .btn-primary:disabled {
  border-color: transparent !important;
  background: #e1dfdf !important;
  box-shadow: none !important;
  color: #928f8f !important;
  transition: 0s !important;
}

.custom-modal .btn-outline-dark {
  color: #222222 !important;
}

.overflow-modal-body {
  max-height: 90vh;
  overflow: auto;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-color: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2)) transparent;
  scrollbar-width: thin;
}

.overflow-modal-body.scroll-auto-hide {
  scrollbar-color: transparent transparent;
}

.overflow-modal-body.scroll-auto-hide:hover {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-color: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2)) transparent;
}

.overflow-modal-body::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

.overflow-modal-body::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: rgba(0, 0, 0, 0.2);
  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  background-clip: padding-box;
}

.overflow-modal-body::-webkit-scrollbar-corner {
  background: transparent;
}

.overflow-modal-body::-webkit-scrollbar-thumb:vertical {
  min-height: 30px;
}

.overflow-modal-body::-webkit-scrollbar-thumb:horizontal {
  min-width: 30px;
}

.overflow-modal-body.scroll-auto-hide::-webkit-scrollbar-thumb {
  background: transparent;
  background-clip: padding-box;
}

.overflow-modal-body.scroll-auto-hide:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
  background-clip: padding-box;
}

.overflow-modal-body::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  border: 3px solid transparent;
}

.overflow-modal-body-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.overflow-modal-body-hidden::-webkit-scrollbar {
  width: 0;
}

.generic-modal .btn-primary.disabled,
.generic-modal .btn-primary:disabled {
  border-color: transparent !important;
  background: #e1dfdf !important;
  box-shadow: none !important;
  color: #928f8f !important;
  transition: 0s !important;
}

.generic-modal .nested-modal {
  z-index: 1091 !important;
}

.generic-modal .btn-outline-dark {
  color: #222222 !important;
}

.generic-modal .btn-outline-dark:hover {
  color: #fff !important;
}

.custom-checkbox-select .ng-dropdown-panel.ng-select-bottom {
  margin-top: 0.25rem;
}

.custom-checkbox-select .ng-dropdown-panel .ng-dropdown-header {
  padding: 0 !important;
}

.custom-checkbox-select .ng-dropdown-panel .ng-option-selected {
  background: white !important;
  font-weight: bold;
  color: #545252 !important;
}

.custom-checkbox-select .ng-dropdown-panel .ng-option-marked {
  background: white !important;
  color: #545252 !important;
  font-weight: bold !important;
}

.custom-checkbox-select .no-result-found-label {
  font-family: Roboto;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

ngb-popover-window.bs-popover-right .arrow::after {
  border-right-color: #222 !important;
}

ngb-popover-window.bs-popover-right .popover-header {
  background-color: #222;
  color: #fff;
  transform: translateY(6vmin);
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

ngb-popover-window.bs-popover-right .popover-body {
  background-color: #222;
  color: #adafb4;
  transform: translateY(5.9vmin);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

ngb-popover-window.bs-popover-right .custom-btn {
  background-color: #fff;
  color: #222;
}

ngb-popover-window.bs-popover-top .arrow::after {
  bottom: -55px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #222;
}

ngb-popover-window.bs-popover-top .popover-header {
  background-color: #222;
  color: #fff;
  transform: translateY(6vmin);
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

ngb-popover-window.bs-popover-top .popover-body {
  background-color: #222;
  color: #adafb4;
  transform: translateY(5.9vmin);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

ngb-popover-window.bs-popover-top .custom-btn {
  background-color: #fff;
  color: #222;
}

ngb-popover-window.bs-popover-bottom .arrow::before {
  top: 6.25vmin;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #222;
  transform: translateX(-18vmin) !important;
}

ngb-popover-window.bs-popover-bottom .arrow::after {
  border-width: 0.5rem 0.5rem 0;
  border-top-color: transparent;
}

ngb-popover-window.bs-popover-bottom .popover-header {
  background-color: #222;
  color: #fff;
  transform: translateY(6vmin);
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
}

ngb-popover-window.bs-popover-bottom .popover-body {
  background-color: #222;
  color: #adafb4;
  transform: translateY(5.9vmin);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

ngb-popover-window.bs-popover-bottom .custom-btn {
  background-color: #fff;
  color: #222;
}

ngb-popover-window.bs-popover-bottom .popover {
  transform: translate(285px, 293px) !important;
}

.content-title,
.content-title-second,
.content-title-third,
.content-subtitle {
  color: #222222;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
}

.content-title {
  margin-bottom: 8px !important;
}

.content-title-second {
  margin-bottom: 24px !important;
}

.content-title-third {
  font-size: 12px;
  line-height: 15.6px;
  margin-bottom: 20px !important;
}

.content-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  margin-bottom: 8px !important;
}

.custom-content-icon {
  max-width: 18px;
}

.icon-option-list-content {
  margin-right: 4rem;
}

.popover {
  max-width: fit-content !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.padding-top-3 {
  padding-top: 3px;
}

.custom-simple-select .ellipsis-format {
  text-overflow: ellipsis;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

hr {
  border: none;
  margin: 0;
  border-top: 1px solid var(--grayscale-lightest, #e0e0e0) !important;
}
