@import './demo';
@import './vendor/styles/_custom-variables/features';
@import './vendor/libs/ngx-toastr/ngx-toastr.scss';
@import './vendor/libs/ngx-chips/ngx-chips.scss';
@import './vendor/libs/ng2-dropdown-menu/ng2-dropdown-menu.scss';
@import './vendor/styles/ng-block-ui.scss';
@import './vendor/libs/spinkit/spinkit.scss';
@import './vendor/libs/ngx-sweetalert2/ngx-sweetalert2.scss';
@import 'plyr/src/sass/plyr.scss';

// BOOTSTRAP BREAKPOINTS MIXINS
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

//quill
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.bubble.css';

@import '../node_modules/angular-calendar/css/angular-calendar.css';

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

// Remove outline on desktops
@media (min-width: 720px) {

	ngb-carousel,
	.dropdown-item,
	ngb-datepicker,
	ngb-rating {
		outline: 0 !important;
	}
}

// Fix horizontal scrollbar issue when .dropdown-menu-right element is shown
.layout-wrapper {
	overflow: hidden;
}

// *******************************************************************************
// * Page transition

.router-transitions {
	opacity: 1;
	transition: opacity 0.2s;

	body.app-loading & {
		opacity: 0.75;
	}
}

// *******************************************************************************
// * Animations

@include keyframes(ngFloatingElementAnimation) {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

html {

	.tooltip,
	.popover {
		animation: ngFloatingElementAnimation 0.25s;
	}

	.dropdown-menu {
		animation: ngFloatingElementAnimation 0.1s;
	}
}

// *******************************************************************************
// * Modal animations

@include keyframes(ngModalBackdropAnimation) {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.5;
	}
}

@include keyframes(ngDefaultModalAnimation) {
	0% {
		opacity: 0;
		transform: translateY(150px) scale(0.8);
	}

	100% {
		opacity: 1;
		transform: translateY(0) scale(1);
	}
}

@include keyframes(ngTopModalAnimation) {
	0% {
		opacity: 0;
		transform: translateY(-100%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@include keyframes(ngSlideModalAnimation) {
	0% {
		opacity: 0;
		transform: translateX(100%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@if $enable-rtl-support {
	@include keyframes(ngRtlSlideModalAnimation) {
		0% {
			opacity: 0;
			transform: translateX(-100%);
		}

		100% {
			opacity: 1;
			transform: translateX(0);
		}
	}
}

@include keyframes(ngFillInModalAnimation) {
	0% {
		opacity: 0;
		transform: scale(0.5, 0.5);
	}

	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}

ngb-modal-backdrop.modal-backdrop {
	opacity: 0.5;
	animation: ngModalBackdropAnimation 0.15s ease-out;
}

ngb-modal-window {
	&.modal .modal-dialog {
		animation: ngDefaultModalAnimation 0.15s ease-out;
	}

	&.modal-top .modal-dialog {
		animation: ngTopModalAnimation 0.15s ease-out;
	}

	&.modal-slide .modal-dialog {
		animation: ngSlideModalAnimation 0.15s ease-out;
	}

	&.modal-fill-in .modal-dialog {
		animation: ngFillInModalAnimation 0.15s ease-out;
	}

	@include feature-rtl {
		&.modal-slide .modal-dialog {
			animation: ngRtlSlideModalAnimation 0.15s ease-out;
		}
	}
}

@if $enable-material-style {
	@include keyframes(ngMaterialModalBackdropAnimation) {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 0.2;
		}
	}

	.material-style ngb-modal-backdrop.modal-backdrop {
		opacity: 0.2;
		animation: ngMaterialModalBackdropAnimation 0.15s ease-out;
	}
}

@include feature-rtl(false) {

	// Prevent horizontal scrollbar
	.dz-hidden-input {
		right: 0 !important;
		left: auto !important;
	}
}

// *******************************************************************************
// * Accordion

ngb-accordion .card-header .btn.btn-link {
	width: 100%;
	padding: 0;
	text-align: inherit;
	white-space: normal;
	border: 0;
	font-size: 0.894rem;
}

.ngb-accordion-with-icon .card-header .btn.btn-link {
	display: flex;
	justify-content: space-between;
}

// *******************************************************************************
// * Typeahead

ngb-typeahead-window .dropdown-item {
	cursor: pointer;
}

// *******************************************************************************
// * [ux-powered/appwork] Fixed table header (#114)
.table-fixed-header {
	position: relative;
	overflow: auto;

	>.table {
		border-collapse: separate;
		border-spacing: 0;

		td+td,
		th+td,
		th+th {
			border-left: 0 !important;
		}

		tr+tr:not(:last-child) th,
		tr+tr:not(:last-child) td,
		tbody tr:first-child th,
		tbody tr:first-child td {
			border-bottom: 0 !important;
		}

		tbody tr:first-child th,
		tbody tr:first-child td {
			border-top: 0 !important;
		}

		>thead th {
			position: sticky;
			position: -webkit-sticky;
			top: 0;
		}
	}
}

.sticky {
	position: fixed;
	top: 67px;
	background-color: white;
	margin-right: 30px;
}

.ui-wh-30 {
	width: 30px !important;
	height: 30px !important;
}

.dashed {
	width: 80px !important;
	height: 80px !important;
	border: 3px dashed rgba(24, 28, 33, 0.1);
}

.nested-modal {
	z-index: 1050 !important;
}

.icon-space {
	margin-right: 1.5vmin;
}

.card-body-2 {
	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

// *******************************************************************************
// * Re: [ux-powered/appwork] theme-settings button (#127)
#theme-settings {
	display: none !important;
}

// SWEET ALERT2 - CUSTOMPRIMARY

.light-style .swal2-popup {
	.swal2-icon.swal2-question.swal-custom-primary {
		border-color: #ffc14f !important;
		color: #ffc14f !important;
	}

	.swal2-icon.swal2-info.swal-custom-primary {
		border-color: #ffc14f !important;
		color: #ffc14f !important;
	}

	.swal2-icon.swal2-error.swal-custom-primary {
		border-color: #ffc14f !important;
		// color: #FFC14F !important;
	}

	.swal2-icon.swal2-error.swal-custom-primary [class^='swal2-x-mark-line'] {
		border-color: #ffc14f;
		background-color: #ffc14f;
	}

	.swal2-icon.swal2-success.swal-custom-primary {
		border-color: #ffc14f !important;
		color: #ffc14f !important;

		[class^='swal2-success-line'] {
			background-color: #ffc14f !important;
		}

		.swal2-success-ring {
			border-color: #ffc14f !important;
		}
	}

	.swal2-close.swal-custom-primary:focus {
		outline: none;
	}

	.swal2-content.swal-custom-primary {
		margin: 1.25rem 1rem 1.25rem 1rem;
		display: flex;
		white-space: pre-line;
		-ms-flex-pack: center;
		justify-content: initial;
		font-size: 1.25rem;
	}

	.swal2-title.swal-custom-primary {
		margin: 1.875rem 0 0.5rem 0;
		font-size: 1.5rem;
	}

	.swal2-actions.swal-custom-primary {
		margin-bottom: 1rem;

		display: flex;
		justify-content: space-evenly;
		white-space: nowrap;
	}
}

.swal-button-offset-padding {

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 256px) {
		width: 12rem;
	}

	@media (min-width: 375px) {
		width: 12rem;
	}

	@media (min-width: 576px) {
		width: 9rem;
	}

	@media (min-width: 768px) {
		width: 12rem;
	}

	@media (min-width: 992px) {
		width: 12rem;
	}

	@media (min-width: 1200px) {
		width: 11rem;
		padding: 10px;
	}

	@media (min-width: 1444px) {
		width: 12rem;
		padding: 10px;
	}
}

.navbar-nav .dropdown-menu {
	position: absolute;
	float: none;
}

.custom-modal {
	min-height: 75vh;

	.modal-body {
		padding-bottom: 1.25rem;
	}

	.btn-primary.disabled,
	.btn-primary:disabled {
		border-color: transparent !important;
		background: #e1dfdf !important;
		box-shadow: none !important;
		color: #928f8f !important;
		transition: 0s !important;
	}

	.btn-outline-dark {
		color: #222222 !important;
	}
}

.overflow-modal-body {
	max-height: 90vh;
	overflow: auto;
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	scrollbar-color: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2)) transparent;
	scrollbar-width: thin;
}

.overflow-modal-body.scroll-auto-hide {
	scrollbar-color: transparent transparent;
}

.overflow-modal-body.scroll-auto-hide:hover {
	scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
	scrollbar-color: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2)) transparent;
}

.overflow-modal-body::-webkit-scrollbar {
	width: 18px;
	height: 18px;
}

.overflow-modal-body::-webkit-scrollbar-thumb {
	border: 6px solid transparent;
	background: rgba(0, 0, 0, 0.2);
	background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
	border-radius: 10px;
	background-clip: padding-box;
}

.overflow-modal-body::-webkit-scrollbar-corner {
	background: transparent;
}

.overflow-modal-body::-webkit-scrollbar-thumb:vertical {
	min-height: 30px;
}

.overflow-modal-body::-webkit-scrollbar-thumb:horizontal {
	min-width: 30px;
}

.overflow-modal-body.scroll-auto-hide::-webkit-scrollbar-thumb {
	background: transparent;
	background-clip: padding-box;
}

.overflow-modal-body.scroll-auto-hide:hover::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
	background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
	background-clip: padding-box;
}

.overflow-modal-body::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.3);
	background-clip: padding-box;
	border: 3px solid transparent;
}

.overflow-modal-body-hidden {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.overflow-modal-body-hidden::-webkit-scrollbar {
	width: 0;
}

.generic-modal {

	.btn-primary.disabled,
	.btn-primary:disabled {
		border-color: transparent !important;
		background: #e1dfdf !important;
		box-shadow: none !important;
		color: #928f8f !important;
		transition: 0s !important;
	}

	.nested-modal {
		z-index: 1091 !important;
	}

	.btn-outline-dark {
		color: #222222 !important;
	}

	.btn-outline-dark:hover {
		color: #fff !important;
	}
}

.custom-checkbox-select {
	.ng-dropdown-panel.ng-select-bottom {
		margin-top: 0.25rem;
	}

	.ng-dropdown-panel .ng-dropdown-header {
		padding: 0 !important;
	}

	.ng-dropdown-panel .ng-option-selected {
		background: white !important;
		font-weight: bold;
		color: #545252 !important;
	}

	.ng-dropdown-panel .ng-option-marked {
		background: white !important;
		color: #545252 !important;
		font-weight: bold !important;
	}

	.no-result-found-label {
		font-family: Roboto;
		font-size: 0.7rem;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0em;
		text-align: left;
	}
}

ngb-popover-window.bs-popover-right {
	.arrow::after {
		border-right-color: #222 !important;
	}

	.popover-header {
		background-color: #222;
		color: #fff;
		transform: translateY(6vmin);
		border-top-right-radius: 4px !important;
		border-top-left-radius: 4px !important;
	}

	.popover-body {
		background-color: #222;
		color: #adafb4;
		transform: translateY(5.9vmin);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.custom-btn {
		background-color: #fff;
		color: #222;
	}
}

ngb-popover-window.bs-popover-top {
	.arrow::after {
		bottom: -55px;
		border-width: 0.5rem 0.5rem 0;
		border-top-color: #222;
	}

	.popover-header {
		background-color: #222;
		color: #fff;
		transform: translateY(6vmin);
		border-top-right-radius: 4px !important;
		border-top-left-radius: 4px !important;
	}

	.popover-body {
		background-color: #222;
		color: #adafb4;
		transform: translateY(5.9vmin);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.custom-btn {
		background-color: #fff;
		color: #222;
	}
}

ngb-popover-window.bs-popover-bottom {
	.arrow::before {
		top: 6.25vmin;
		border-width: 0 0.5rem 0.5rem 0.5rem;
		border-bottom-color: #222;
		transform: translateX(-18vmin) !important;
	}

	.arrow::after {
		border-width: 0.5rem 0.5rem 0;
		border-top-color: transparent;
	}

	.popover-header {
		background-color: #222;
		color: #fff;
		transform: translateY(6vmin);
		border-top-right-radius: 4px !important;
		border-top-left-radius: 4px !important;
	}

	.popover-body {
		background-color: #222;
		color: #adafb4;
		transform: translateY(5.9vmin);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.custom-btn {
		background-color: #fff;
		color: #222;
	}

	.popover {
		transform: translate(285px, 293px) !important;
	}
}

.content-title,
.content-title-second,
.content-title-third,
.content-subtitle {
	color: #222222;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 700;
	line-height: 20.8px;
}

.content-title {
	margin-bottom: 8px !important;
}

.content-title-second {
	margin-bottom: 24px !important;
}

.content-title-third {
	font-size: 12px;
	line-height: 15.6px;
	margin-bottom: 20px !important;
}

.content-subtitle {
	font-size: 12px;
	font-weight: 400;
	line-height: 15.6px;
	margin-bottom: 8px !important;
}

.custom-content-icon {
	max-width: 18px;
}

.icon-option-list-content {
	margin-right: 4rem;
}

.popover {
	max-width: fit-content !important;
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
}

.padding-top-3 {
	padding-top: 3px;
}

.custom-simple-select .ellipsis-format {
	text-overflow: ellipsis;
	overflow: hidden;
}

.cursor-pointer {
	cursor: pointer;
}

hr {
	border: none;
	margin: 0;
	border-top: 1px solid var(--grayscale-lightest, #e0e0e0) !important;
}